<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create New Njangi Group</p>
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="admin_username"
              ref="admin_username"
              outlined dense
              label="Search njangi admin..."
            ></v-text-field>
          </v-col>

          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selected_admin"
              ref="selected_admin"
              :items="users"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select the njangi admin"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              v-model="njangi_name"
              ref="njangi_name"
              outlined dense
              label="Enter the njangi name"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="groupStartDate"
              dense
              type="date"
              label="Njangi Group Start Date"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="njangi_contact"
              ref="njangi_contact"
              outlined
              dense
              label="Input Njangi Contact (2376XXXXXXXX)"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-select
              v-model="njangi_cycle"
              ref="njangi_cycle"
              outlined
              dense
              :items="njangi_cycles"
              label="Select Njangi Cycle"
            ></v-select>
          </v-col>

          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="njangi_amount"
              ref="njangi_amount"
              outlined type="number"
              dense
              label="Njangi Contribution Amount"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="njangi_image"
              ref="product_cover_image"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Njangi Image"
            ></v-file-input>
          </v-col>

          <v-col cols="12" >
            <v-text-field 
                class="pa-0 mt-0"
                id="njangi_description" 
                ref="njangi_description" 
                v-model="njangi_description"
                label="Njangi Description"
                color="teal"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="createNjangi()"
        >
          Create Njangi
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      njangi_name: "",
      selected_admin: "",
      njangi_contact: "",
      
      njangi_cycle: "",
      njangi_amount: "",
      njangi_description: "",
      admin_username: "",

      njangi_cycles: ['Daily', 'Weekly', 'Monthly'],
      users: [],
      njangi_image: [],
      groupStartDate: "",

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Njangi | Create Njangi"
  },

  methods:{
    getUsers(){
      axios
        .get('/api/v1/manager/get/clients/'+this.admin_username+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into users
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async createNjangi(){
        if(this.selected_admin === ""){
            this.$store.commit('setSnackBarMessage', "Please select the njangi administrator.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selected_admin.focus()  // this causes a an auto fucos to the element
        }
        else if(this.njangi_cycle === ""){
            this.$store.commit('setSnackBarMessage', "Please select the njangi cycle")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.njangi_cycle.focus()  // this causes a an auto fucos to the element
        }
        else if(this.groupStartDate === ""){
            this.$store.commit('setSnackBarMessage', "Please select the njangi group Start Date")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.groupStartDate.focus()  // this causes a an auto fucos to the element
        }
        else if(this.njangi_amount === "" || String(Number(this.njangi_amount)) === "NaN"){
          this.$store.commit('setSnackBarMessage', "Please input the njangi contribution amount")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.njangi_amount.focus()  // this causes a an auto fucos to the element
        }
        else if(this.njangi_contact === ""){
            this.$store.commit('setSnackBarMessage', "Please input the njangi contact.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
        }
        else if(this.njangi_name === ""){
            this.$store.commit('setSnackBarMessage', "Please input the njangi name.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
        }
        else if(!this.njangi_image){
            this.$store.commit('setSnackBarMessage', "Please input the njangi image file.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
        }
        else if(this.njangi_description === ""){
            this.$store.commit('setSnackBarMessage', "Please give the njangi description.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            formData.append('selected_admin_id', this.selected_admin)
            formData.append('njangi_cycle', this.njangi_cycle)
            formData.append('groupStartDate', this.groupStartDate)
            formData.append('njangi_amount', this.njangi_amount)
            formData.append('njangi_name', this.njangi_name)
            formData.append('njangi_image', this.njangi_image)
            formData.append('njangi_contact', this.njangi_contact)
            formData.append('njangi_description', this.njangi_description)

            await axios
                .post('/api/v1/manager/njangi/create/group/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      let router = this.$router
                      setTimeout(function(){
                          router.push('/njangi/view/groups')
                      }, 4000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })

            this.loading = false
        }

    },
  },
}
</script>
